import React from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type OrganizationQueryResult,
  type Organization,
  OrganizationTableUpdateArg,
} from "../../types/organizations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useCreateOrganization({ table }: OrganizationTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organization: Organization) => {
      try {
        const response = await apiClient.post(
          "/api/organizations/",
          organization
        );

        table.setCreatingRow(null);
        toast.success(`Organization ${organization.name} created successfully`);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error creating organization: ${error}`);
        }
      }
    },
    onSuccess: (newOrganization: Organization) => {
      queryClient.setQueryData<Organization[]>(
        ["organizations"],
        (prevOrganizations = []) => [...prevOrganizations, newOrganization]
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });
}

export function useDeleteOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organizationId: string) => {
      try {
        await apiClient.delete(`/api/organizations/${organizationId}`);
        toast.success("Organization successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the organization");
      }
    },
    onMutate: (organizationId: string) => {
      queryClient.setQueryData(["organizations"], (prevOrganizations: any) =>
        prevOrganizations?.filter(
          (organization: Organization) => organization.id !== organizationId
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });
}

export function useSearchOrganizations({ q }: { q: string }) {
  return useQuery<OrganizationQueryResult[]>({
    queryKey: ["users", q],
    queryFn: async () => {
      try {
        const url = `/api/organizations?search=${q}`;

        const response = await apiClient.get(url);

        return [
          {
            organizations: response.data.results as Organization[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ organizations: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useGetAllOrganizations() {
  return useQuery<OrganizationQueryResult[]>({
    queryKey: ["all_organizations"],
    queryFn: async () => {
      try {
        const url = `/api/organizations/`;

        const response = await apiClient.get(url);
        return [
          {
            organizations: response.data.results as Organization[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ organizations: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetOrganizations({
  pagination,
  columnFilters,
  sorting,
  globalFilter,
  exportCsv,
}: {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  globalFilter: string | undefined;
  exportCsv?: boolean;
}) {
  return useQuery<OrganizationQueryResult[]>({
    queryKey: [
      "organizations",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      exportCsv,
    ],
    queryFn: async () => {
      try {
        const url = `/api/organizations/`;
        const params = new URLSearchParams({
          page: (pagination.pageIndex + 1).toString(),
          limit: pagination.pageSize.toString(),
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
          globalFilter: globalFilter ?? "",
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            organizations: response.data.results as Organization[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ organizations: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateOrganization({ table }: OrganizationTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organization: Organization) => {
      try {
        await apiClient.patch(
          `/api/organizations/${organization.id}/`,
          organization
        );

        table.setEditingRow(null);
        toast.success(`Organization ${organization.name} updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating organization ${error}`);
        }
      }
    },

    onMutate: (newOrganizationInfo: Organization) => {
      queryClient.setQueryData(["organizations"], (prevOrganizations: any) =>
        prevOrganizations?.map((prevOrganization: Organization) =>
          prevOrganization.id === newOrganizationInfo.id
            ? newOrganizationInfo
            : prevOrganization
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["organizations"] }),
  });
}

export const useExportOrganizations = (columnFilters: any, sorting: any) => {
  const exportOrganizations = async () => {
    try {
      const response = await apiClient.get("/api/organizations/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Organizations-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportOrganizations;
};

export const useDeleteOrganizations = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteOrganizations = async () => {
    try {
      await apiClient.get("/api/organizations/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteOrganizations;
};
