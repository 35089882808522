import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Grid, Button } from "@mui/material";
import { EvidenceRequest } from "../../types/evidencerequests";
import FileSearchPage from "./searchFiles";
import FileList from "./fileList";
import EvidenceFilesTable from "./fileList";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../helpers/user";

interface EvidenceFileManagerProps {
  evidenceRequest: EvidenceRequest;
}

const EvidenceFileManager = ({ evidenceRequest }: EvidenceFileManagerProps) => {
  const [admin, setAdmin] = useState(false);
  const userInfo = getUserInfo();

  useEffect(() => {
    if (userInfo) {
      setAdmin(userInfo.role === "admin");
    }
  }, [userInfo]);

  return (
    <Paper sx={{ padding: 1 }}>
      <Typography variant="h5" sx={{ p: 4, pb: 0 }}>
        Evidence Files
      </Typography>

      <Grid>
        <EvidenceFilesTable evidenceRequest={evidenceRequest} />

        {evidenceRequest && admin && (
          <Box sx={{ p: 2, textAlign: "right" }}>
            <Button variant="contained">
              <Link
                to={`/file-search/?organization=${
                  evidenceRequest.organization?.id
                }&evidenceRequests=${
                  evidenceRequest.id
                }&engagements=${evidenceRequest.engagements
                  ?.map((engagement) => engagement.id)
                  .join(",")}`}
              >
                <Typography sx={{ color: "white" }}>Browse Files</Typography>
              </Link>
            </Button>
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

export default EvidenceFileManager;
