import React, { useState, useMemo, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  Autocomplete,
  Typography,
  Paper,
  Tooltip,
  Chip,
  CircularProgress,
} from "@mui/material";
import { Search, ChevronLeft, ChevronRight } from "@mui/icons-material";
import debounce from "lodash/debounce";

import { useSearchEvidenceFiles } from "../../hooks/evidencefiles";
import FileCard from "./fileCard";
import { useGetAllOrganizations } from "../../hooks/organizations";
import {
  useGetEvidenceRequest,
  useSearchEvidenceRequestsForFiles,
} from "../../hooks/evidencerequests";
import { Organization } from "../../types/organizations";
import {
  useGetEngagementsForFiles,
  useSearchEngagements,
} from "../../hooks/engagements";
import { EvidenceRequest } from "../../types/evidencerequests";
import { Engagement } from "../../types/engagements";
import Layout from "../../layout";
import { useSearchParams } from "react-router-dom";
import { getUserInfo } from "../../helpers/user";

const FileSearchPage = ({ id }: { id?: string }) => {
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProcessingStatus, setSelectedProcessingStatus] = useState("");
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [selectedEvidenceRequests, setSelectedEvidenceRequests] = useState<
    EvidenceRequest[]
  >([]);
  const [selectedEngagements, setSelectedEngagements] = useState<Engagement[]>(
    []
  );
  const [fileType, setFileType] = useState("");
  const [uploadedBy, setUploadedBy] = useState("");
  const [evidenceRequestSearch, setEvidenceRequestSearch] = useState("");
  const [engagementSearch, setEngagementSearch] = useState("");
  const [hasSelectedEngagement, setHasSelectedEngagement] = useState(false);

  const organizationId = searchParams.get("organization");
  const evidenceRequestIds =
    searchParams.get("evidenceRequests")?.split(",") || [];
  const engagementIds = searchParams.get("engagements")?.split(",") || [];

  const { data: orgsData } = useGetAllOrganizations();
  const { data: initialEvidenceRequests, isLoading: loadingEvidenceRequests } =
    useGetEvidenceRequest({
      id: evidenceRequestIds.join(","),
    });
  const { data: initialEngagements, isLoading: loadingEngagements } =
    useGetEngagementsForFiles({
      ids: engagementIds,
    });
  const { data: engagementsSearchResults } = useSearchEngagements({
    q: engagementSearch,
    organization: selectedOrg?.id,
  });

  const user = getUserInfo();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(user.role === "admin");
  }, [user]);

  const {
    searchEvidenceFiles,
    reProcess,
    loading,
    searchResults,
    totalCount,
    hasNextPage,
    hasPrevPage,
    currentPage,
  } = useSearchEvidenceFiles();

  const {
    searchEvidenceRequests,
    loading: loadingEvidenceRequestSearch,
    searchResults: evidenceRequestsSearchResults,
  } = useSearchEvidenceRequestsForFiles();

  useEffect(() => {
    if (organizationId && orgsData) {
      const org = orgsData[0].organizations.find(
        (org) => org.id === organizationId
      );
      if (org) setSelectedOrg(org);
    }
  }, [organizationId, orgsData]);

  useEffect(() => {
    if (initialEvidenceRequests?.evidencerequests) {
      setSelectedEvidenceRequests(initialEvidenceRequests.evidencerequests);
    }
  }, [initialEvidenceRequests]);

  useEffect(() => {
    if (initialEngagements?.[0]?.engagements) {
      const engagements = initialEngagements[0].engagements;
      setSelectedEngagements(engagements);
      setHasSelectedEngagement(engagements.length > 0);
    }
  }, [initialEngagements]);

  useEffect(() => {
    if (
      selectedOrg &&
      !loadingEvidenceRequests &&
      !loadingEngagements &&
      (selectedEvidenceRequests.length > 0 || selectedEngagements.length > 0)
    ) {
      handleSearch();
    }
  }, [
    selectedOrg,
    selectedEvidenceRequests,
    selectedEngagements,
    loadingEvidenceRequests,
    loadingEngagements,
  ]);

  const uniqueFiles = useMemo(() => {
    const fileMap = new Map();
    searchResults?.forEach((file) => {
      if (!fileMap.has(file.id)) fileMap.set(file.id, file);
    });
    return Array.from(fileMap.values());
  }, [searchResults]);

  const handleReProcess = () => {
    window.confirm(
      `Are you sure you want to re-process all ${totalCount} files with the current search filters?`
    ) &&
      reProcess(
        searchQuery,
        selectedOrg?.id,
        selectedEvidenceRequests.map((req) => req.id),
        selectedEngagements.map((eng) => eng.id),
        fileType,
        uploadedBy,
        undefined,
        undefined,
        selectedProcessingStatus
      );
  };

  const handlePageChange = (newPage: number) => {
    searchEvidenceFiles(
      searchQuery,
      selectedOrg?.id,
      selectedEvidenceRequests.map((req) => req.id),
      selectedEngagements.map((eng) => eng.id),
      fileType,
      uploadedBy,
      undefined,
      undefined,
      selectedProcessingStatus,
      newPage
    );
  };

  const debouncedEvidenceRequestSearch = useMemo(
    () =>
      debounce((value: string) => {
        if (selectedOrg && selectedEngagements.length > 0) {
          searchEvidenceRequests(
            value,
            selectedOrg.id,
            selectedEngagements.map((eng) => eng.id)
          );
        }
      }, 300),
    [selectedOrg, selectedEngagements, searchEvidenceRequests]
  );

  const debouncedEngagementSearch = useMemo(
    () => debounce((value: string) => setEngagementSearch(value), 300),
    []
  );

  useEffect(() => {
    if (selectedOrg && selectedEngagements.length > 0) {
      debouncedEvidenceRequestSearch(evidenceRequestSearch);
    }
  }, [selectedOrg, selectedEngagements, evidenceRequestSearch]);

  const handleEvidenceRequestsChange = (
    _: any,
    newValue: EvidenceRequest[]
  ) => {
    setSelectedEvidenceRequests(newValue);

    const newSearchParams = new URLSearchParams(searchParams);
    if (newValue.length > 0) {
      newSearchParams.set(
        "evidenceRequests",
        newValue.map((req) => req.id).join(",")
      );
    } else {
      newSearchParams.delete("evidenceRequests");
    }
    window.history.pushState({}, "", `?${newSearchParams.toString()}`);
  };

  const handleOrganizationChange = (_: any, newValue: Organization | null) => {
    setSelectedOrg(newValue);
    setSelectedEvidenceRequests([]);
    setSelectedEngagements([]);
    setEvidenceRequestSearch("");
    setEngagementSearch("");
    setHasSelectedEngagement(false);
  };

  const handleEngagementChange = (_: any, newValue: Engagement[]) => {
    setSelectedEngagements(newValue);
    setHasSelectedEngagement(newValue.length > 0);
    if (newValue.length === 0) {
      setSelectedEvidenceRequests([]);
    }
  };

  const handleSearch = (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!selectedOrg) return;
    searchEvidenceFiles(
      searchQuery,
      selectedOrg.id,
      selectedEvidenceRequests.map((req) => req.id),
      selectedEngagements.map((eng) => eng.id),
      fileType,
      uploadedBy,
      undefined,
      undefined,
      selectedProcessingStatus
    );
  };

  const clearFilters = () => {
    setSearchQuery("");
    setFileType("");
    setUploadedBy("");
    setSelectedEvidenceRequests([]);
    setSelectedEngagements([]);
    setEvidenceRequestSearch("");
    setEngagementSearch("");
    setHasSelectedEngagement(false);
    searchEvidenceFiles("", selectedOrg?.id || "", [], [], "", "", "", "", "");
  };

  const ResultsSection = () => (
    <Box sx={{ p: 3, overflow: "auto", flex: 1 }}>
      {searchResults && searchResults.length > 0 && (
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography color="text.secondary">{totalCount} results</Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              size="small"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={!hasPrevPage || loading}
              startIcon={<ChevronLeft />}
              variant="outlined"
            >
              Previous
            </Button>
            <Button
              size="small"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={!hasNextPage || loading}
              endIcon={<ChevronRight />}
              variant="outlined"
            >
              Next
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            {uniqueFiles?.map((file) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
                <FileCard file={file} />
              </Grid>
            ))}
          </Grid>

          {!loading && uniqueFiles.length === 0 && (
            <Box sx={{ textAlign: "center", py: 4 }}>
              <Typography color="text.secondary">No results</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );

  return (
    <>
      <Layout pageTitle="Evidence Files Search" parentUrl="" parentPageName="">
        <Box sx={{ display: "flex", height: "100%" }}>
          <Paper
            component="form"
            onSubmit={handleSearch}
            sx={{
              minWidth: 300,
              maxWidth: 300,
              p: 3,
              borderRight: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              height: "100%",
              overflow: "auto",
            }}
            elevation={0}
            square
          >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Search Filters
            </Typography>

            <Autocomplete
              value={selectedOrg}
              onChange={handleOrganizationChange}
              options={(orgsData && orgsData?.[0].organizations) || []}
              getOptionLabel={(option: Organization) => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  required
                  variant="outlined"
                  size="small"
                />
              )}
            />

            <Autocomplete
              multiple
              disabled={!selectedOrg}
              value={selectedEngagements}
              onChange={handleEngagementChange}
              options={engagementsSearchResults?.[0].engagements || []}
              getOptionLabel={(option: Engagement) => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onInputChange={(_, value) => debouncedEngagementSearch(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Engagements"
                  variant="outlined"
                  size="small"
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Tooltip title={option.name} key={option.id}>
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  </Tooltip>
                ))
              }
            />
            <Autocomplete
              multiple
              disabled={!selectedOrg || !hasSelectedEngagement}
              value={selectedEvidenceRequests}
              onChange={handleEvidenceRequestsChange}
              options={evidenceRequestsSearchResults || []}
              getOptionLabel={(option: EvidenceRequest) => option.title || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onInputChange={(_, value) =>
                debouncedEvidenceRequestSearch(value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Evidence Requests"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingEvidenceRequests ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Tooltip title={option.title} key={option.id}>
                    <Chip
                      label={option.title}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  </Tooltip>
                ))
              }
            />

            <TextField
              fullWidth
              label="Free Text Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={!selectedOrg}
            />

            <Autocomplete
              fullWidth
              title="Processing Status"
              options={["PROCESSING", "COMPLETED", "FAILED", "STUCK"]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Processing Status"
                  variant="outlined"
                  size="small"
                />
              )}
              size="small"
              value={selectedProcessingStatus}
              onChange={(_, value) => setSelectedProcessingStatus(value || "")}
              disabled={!selectedOrg}
            />

            <TextField
              fullWidth
              label="File name"
              variant="outlined"
              size="small"
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              placeholder="File names or extensions e.g., pdf, doc, xlsx"
              disabled={!selectedOrg}
            />

            <TextField
              fullWidth
              label="Uploaded By"
              variant="outlined"
              size="small"
              value={uploadedBy}
              onChange={(e) => setUploadedBy(e.target.value)}
              disabled={!selectedOrg}
            />

            <Box sx={{ pt: 2 }}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                startIcon={<Search />}
                disabled={!selectedOrg}
              >
                Search
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearFilters}
                sx={{ mt: 1 }}
                disabled={!selectedOrg}
              >
                Clear Filters
              </Button>
              {isAdmin && (
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleReProcess}
                  sx={{ top: 150 }}
                  disabled={!selectedOrg}
                >
                  Re-process filtered files
                </Button>
              )}
            </Box>
          </Paper>

          {ResultsSection()}
        </Box>
      </Layout>
    </>
  );
};

export default FileSearchPage;
