import React from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type ScopeQueryResult,
  type Scope,
  ScopeTableUpdateArg,
} from "../../types/scopes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useCreateScope({ table }: ScopeTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (scope: Scope) => {
      try {
        const response = await apiClient.post("/api/scopes/", scope);

        table.setCreatingRow(null);
        toast.success(`Scope ${scope.title} created successfully`);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error creating scope: ${error}`);
        }
      }
    },
    onSuccess: (newScope: Scope) => {
      queryClient.setQueryData<Scope[]>(["scopes"], (prevScopes = []) => [
        ...prevScopes,
        newScope,
      ]);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["scopes"] }),
  });
}

export function useDeleteScope() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (scopeId: string) => {
      try {
        await apiClient.delete(`/api/scopes/${scopeId}/`);
        toast.success("Scope successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the scope");
      }
    },
    onMutate: (scopeId: string) => {
      queryClient.setQueryData(["scopes"], (prevScopes: any) =>
        prevScopes?.filter((scope: Scope) => scope.id !== scopeId)
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["scopes"] }),
  });
}

export function useGetScopes({
  pagination,
  columnFilters,
  sorting,
  globalFilter,
  exportCsv,
}: {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  globalFilter: string | undefined;
  exportCsv?: boolean;
}) {
  return useQuery<ScopeQueryResult[]>({
    queryKey: [
      "scopes",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      exportCsv,
    ],
    queryFn: async () => {
      try {
        const url = `/api/scopes/`;
        const params = new URLSearchParams({
          page: (pagination.pageIndex + 1).toString(),
          limit: pagination.pageSize.toString(),
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
          globalFilter: globalFilter ?? "",
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            scopes: response.data.results as Scope[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ scopes: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateScope({ table }: ScopeTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (scope: Scope) => {
      try {
        await apiClient.patch(`/api/scopes/${scope.id}/`, scope);

        table.setEditingRow(null);
        toast.success(`Scope ${scope.title}  updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating scope ${error}`);
        }
      }
    },

    onMutate: (newScopeInfo: Scope) => {
      queryClient.setQueryData(["scopes"], (prevScopes: any) =>
        prevScopes?.map((prevScope: Scope) =>
          prevScope.id === newScopeInfo.id ? newScopeInfo : prevScope
        )
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["scopes"] }),
  });
}

export const useExportScopes = (columnFilters: any, sorting: any) => {
  const exportScopes = async () => {
    try {
      const response = await apiClient.get("/api/scopes/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Scopes-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportScopes;
};

export const useDeleteScopes = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteScopes = async () => {
    try {
      await apiClient.get("/api/scopes/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteScopes;
};

export function useSearchScopes({ q }: { q: string }) {
  return useQuery<ScopeQueryResult[]>({
    queryKey: ["scopes", q],
    queryFn: async () => {
      try {
        const url = `/api/scopes/?search=${q}`;

        const response = await apiClient.get(url);

        return [
          {
            scopes: response.data.results as Scope[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ scopes: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}
