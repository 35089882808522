import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { isUserAllowed } from "../../helpers/user";
import { clearCookie, getCookie } from "../../api/apiClient";
import { SendLogoutApiRequest } from "./Logout";

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const isAuthenticated = checkAuthentication();

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export const checkAuthentication = (): boolean => {
  const sessionExpiryString = getCookie("session_expiry");
  if (!sessionExpiryString) return false;

  const sessionExpiry = new Date(sessionExpiryString);
  const now = new Date();

  if (now > sessionExpiry) {
    localStorage.removeItem("last_viewed_engagement");
    return false;
  }

  const accessEnabled = isUserAllowed();
  const navigate = useNavigate();

  if (!accessEnabled) {
    window.alert(
      "Your access to the portal has been disabled. Please contact your Organization Administrator."
    );

    SendLogoutApiRequest();
    return false;
  }

  return true;
};
export default RequireAuth;
