import React from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type ControlSetQueryResult,
  type ControlSet,
  ControlSetTableUpdateArg,
} from "../../types/controlsets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useCreateControlSet(table?: ControlSetTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (controlset: ControlSet) => {
      const updatedControlSet = {
        ...controlset,
        controls: controlset.controls?.map((control) => control.id),
        compliance_standards: controlset.compliance_standards?.map(
          (compliance_standard) => compliance_standard.id
        ),
      };

      try {
        const response = await apiClient.post(
          "/api/controlsets/",
          updatedControlSet
        );

        if (table) {
          table.table.setCreatingRow(null);
        }

        toast.success(`Control Set ${controlset.title} created successfully`);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error creating Control Set: ${error}`);
        }
      }
    },
    onSuccess: (newControlSet: ControlSet) => {
      queryClient.setQueryData<ControlSet[]>(
        ["controlsets"],
        (prevControlSets = []) => [...prevControlSets, newControlSet]
      );
    },

    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["controlsets"] }),
  });
}

export function useDeleteControlSet() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (controlsetId: string) => {
      try {
        await apiClient.delete(`/api/controlsets/${controlsetId}/`);
        toast.success("Control Set successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the Control Set");
      }
    },
    onMutate: (controlsetId: string) => {
      queryClient.setQueryData(["controlsets"], (prevControlSets: any) =>
        prevControlSets?.filter(
          (controlset: ControlSet) => controlset.id !== controlsetId
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["controlsets"] }),
  });
}

export function useGetControlSet({ id }: { id: string }) {
  return useQuery<ControlSetQueryResult[]>({
    queryKey: ["controlsets", id],
    queryFn: async () => {
      try {
        const url = `/api/controlsets/`;
        const params = new URLSearchParams({
          id: id,
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            controlsets: response.data.results as ControlSet[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ controlsets: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useSearchControlSets({ q }: { q: string }) {
  return useQuery<ControlSetQueryResult[]>({
    queryKey: ["controlsets", q],
    queryFn: async () => {
      try {
        const url = `/api/controlsets/?search=${q}`;

        const response = await apiClient.get(url);

        return [
          {
            controlsets: response.data.results as ControlSet[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ controlsets: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useGetControlSets({
  pagination,
  columnFilters,
  sorting,
  globalFilter,
  exportCsv,
}: {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  globalFilter: string | undefined;
  exportCsv?: boolean;
}) {
  return useQuery<ControlSetQueryResult[]>({
    queryKey: [
      "controlsets",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      exportCsv,
    ],
    queryFn: async () => {
      try {
        const url = `/api/controlsets/`;
        const params = new URLSearchParams({
          page: (pagination.pageIndex + 1).toString(),
          limit: pagination.pageSize.toString(),
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
          globalFilter: globalFilter ?? "",
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            controlsets: response.data.results as ControlSet[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ controlsets: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

type PatchControlSetInput = {
  controlSetId: string;
  controlId?: string;
  complianceStandardId?: string;
};

export function usePatchControlSet() {
  return useMutation({
    mutationFn: async ({
      controlSetId,
      controlId,
      complianceStandardId,
    }: PatchControlSetInput) => {
      const patchData: any = {};
      if (controlId) {
        patchData.control = controlId;
      } else if (complianceStandardId) {
        patchData.compliance_standards = complianceStandardId;
      }

      try {
        await apiClient.patch(`/api/controlsets/add/`, patchData, {
          params: { controlset: controlSetId },
        });
        toast.success(`ControlSet updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating ControlSet: ${error}`);
        }
      }
    },
  });
}

export function useUnPatchControlSet() {
  return useMutation({
    mutationFn: async ({
      controlSetId,
      controlId,
      complianceStandardId,
    }: PatchControlSetInput) => {
      const patchData: any = {};
      if (controlId) {
        patchData.control = controlId;
      } else if (complianceStandardId) {
        patchData.compliance_standards = complianceStandardId;
      }

      try {
        await apiClient.patch(`/api/controlsets/remove/`, patchData, {
          params: { controlset: controlSetId },
        });
        toast.success(`ControlSet updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating ControlSet: ${error}`);
        }
      }
    },
  });
}

type PatchControlSetByTitleInput = {
  controlSetId: string;
  controlNames?: string[];
  complianceStandardTitles?: string[];
};

export function usePatchControlSetByTitle() {
  return useMutation({
    mutationFn: async ({
      controlSetId,
      controlNames,
      complianceStandardTitles,
    }: PatchControlSetByTitleInput) => {
      const patchData: any = {};
      if (controlNames) {
        patchData.controls = controlNames;
      } else if (complianceStandardTitles) {
        patchData.compliance_standards = complianceStandardTitles;
      }

      try {
        await apiClient.patch(`/api/controlsets/add-by-title/`, patchData, {
          params: { controlset: controlSetId },
        });
        toast.success(`ControlSet updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating ControlSet: ${error}`);
        }
      }
    },
  });
}

export function useUnPatchControlSetByTitle() {
  return useMutation({
    mutationFn: async ({
      controlSetId,
      controlNames,
      complianceStandardTitles,
    }: PatchControlSetByTitleInput) => {
      const patchData: any = {};
      if (controlNames) {
        patchData.controls = controlNames;
      } else if (complianceStandardTitles) {
        patchData.compliance_standards = complianceStandardTitles;
      }

      try {
        await apiClient.patch(`/api/controlsets/remove-by-title/`, patchData, {
          params: { controlset: controlSetId },
        });
        toast.success(`ControlSet updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating ControlSet: ${error}`);
        }
      }
    },
  });
}

export function useUpdateControlSet(table?: ControlSetTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (controlset: ControlSet) => {
      const updatedControlSet = {
        ...controlset,
        controls: controlset.controls?.map((control) => control.id),
        compliance_standards: controlset.compliance_standards?.map(
          (compliance_standard) => compliance_standard.id
        ),
      };

      try {
        await apiClient.patch(
          `/api/controlsets/${controlset.id}/`,
          updatedControlSet
        );

        if (table) {
          table.table.setEditingRow(null);
        }
        toast.success(`Control Set ${controlset.title} updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error updating Control Set ${error}`);
        }
      }
    },

    onMutate: (newControlSetInfo: ControlSet) => {
      queryClient.setQueryData(["controlsets"], (prevControlSets: any) =>
        prevControlSets?.map((prevControlSet: ControlSet) =>
          prevControlSet.id === newControlSetInfo.id
            ? newControlSetInfo
            : prevControlSet
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["controlsets"] }),
  });
}

export const useExportControlSets = (columnFilters: any, sorting: any) => {
  const exportControlSets = async () => {
    try {
      const response = await apiClient.get("/api/controlsets/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `ControlSets-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportControlSets;
};

export const useDeleteControlSets = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteControlSets = async () => {
    try {
      await apiClient.get("/api/controlsets/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteControlSets;
};
