import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, IconButton, Box } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { EvidenceFile } from "../../types/evidencefiles";
import EvidenceFileViewer from "./viewFile";
import { GridCloseIcon } from "@mui/x-data-grid";
import { EvidenceRequest } from "../../types/evidencerequests";

export const EvidenceFileButton = ({
  file,
  evidenceRequest,
  iconVersion = false,
  smallIcon = false,
  pageNumber = 1,
  inChat = false,
  inPDFReader = false,
}: {
  file: EvidenceFile;
  evidenceRequest?: EvidenceRequest;
  iconVersion?: boolean;
  smallIcon?: boolean;
  pageNumber?: number;
  inChat?: boolean;
  inPDFReader?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      {iconVersion &&
        (inPDFReader ? (
          <IconButton
            onClick={() => {
              const element = document.querySelector(
                `[data-page-number="${pageNumber}"]`
              );
              element?.scrollIntoView({ behavior: "smooth" });
            }}
            size="small"
            title="Open"
            color="primary"
          >
            <Launch sx={{ fontSize: smallIcon ? "1rem" : "1.5rem" }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleOpen}
            size="small"
            title="Open"
            color="primary"
          >
            <Launch sx={{ fontSize: smallIcon ? "1rem" : "1.5rem" }} />
          </IconButton>
        ))}

      {!iconVersion && (
        <Button variant="contained" onClick={handleOpen} sx={{ mt: 2 }}>
          Open File
        </Button>
      )}

      <Dialog open={isOpen} onClose={handleClose} fullScreen>
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
            <IconButton
              sx={{ position: "absolute", zIndex: 1000, top: 0, right: 0 }}
              onClick={handleClose}
              size="medium"
            >
              <GridCloseIcon />
            </IconButton>
          </Box>
          <Box>
            {evidenceRequest && (
              <EvidenceFileViewer
                pageNumber={pageNumber}
                evidenceFile={file}
                evidenceRequest={evidenceRequest}
              />
            )}
            {!evidenceRequest && (
              <EvidenceFileViewer pageNumber={pageNumber} evidenceFile={file} />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EvidenceFileButton;
