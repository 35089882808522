import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Collapse,
  Alert,
  AlertTitle,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Refresh,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { useProcessEvidenceFiles } from "../../hooks/evidencefiles";
import { EvidenceFile } from "../../types/evidencefiles";
import EvidenceFileButton from "./evidenceFileButton";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../helpers/user";

const FileCard = ({ file }: { file: EvidenceFile }) => {
  const { processFile } = useProcessEvidenceFiles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showError, setShowError] = useState(false);

  const user = getUserInfo();

  useEffect(() => {
    if (user.role === "admin" && file.conversion_status === "FAILED") {
      setShowError(true);
    }
  }, [file, user]);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "success.main";
      case "failed":
        return "error.main";
      case "processing":
        return "warning.main";
      default:
        return "text.secondary";
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
      }}
    >
      <CardContent sx={{ flex: 1, p: 1, pl: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            title={file.original_name}
            sx={{
              fontSize: "1.1rem",
              overflow: "scroll",
              textOverflow: "scroll",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "horizontal",
            }}
          >
            {file.original_name}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          {file.queued ? (
            <Typography variant="subtitle2" sx={{ color: "grey" }}>
              QUEUED FOR PROCESSING
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{ color: getStatusColor(file.conversion_status || "") }}
            >
              {file.conversion_status}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
          <Avatar sx={{ width: 24, height: 24, fontSize: "0.875rem" }}>
            {file.user?.name?.[0]}
          </Avatar>
          <Typography variant="body2" color="text.secondary">
            {file.user?.name}
          </Typography>
        </Box>

        <Typography variant="body2">
          Evidence Requests:{" "}
          {file.evidence_requests?.map((er) => (
            <Link
              to={`/evidence-request/${er.id}`}
              target="_blank"
              key={er.id}
              style={{
                display: "inline-block",
                border: "1px solid #e3e3e3",
                borderRadius: "2px",
                padding: "2px 4px",
                margin: "4px 2px",
                whiteSpace: "nowrap",
              }}
            >
              {er.title}
            </Link>
          ))}
        </Typography>
        <Typography variant="body2">
          Engagements:{" "}
          {file.engagements?.map((engagement) => (
            <Link
              to={`/engagement/${engagement.id}`}
              target="_blank"
              key={engagement.id}
              style={{
                display: "inline-block",
                border: "1px solid #e3e3e3",
                borderRadius: "2px",
                padding: "2px 4px",
                margin: "4px 2px",
                whiteSpace: "nowrap",
              }}
            >
              {engagement.name}
            </Link>
          ))}
        </Typography>

        <EvidenceFileButton file={file} />

        {showError && (
          <Box sx={{ mt: 2 }}>
            <Button
              size="small"
              variant="text"
              onClick={() => setIsExpanded(!isExpanded)}
              endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
              sx={{ color: "error.main" }}
              startIcon={<ErrorIcon />}
            >
              Error Details
            </Button>
            <Collapse in={isExpanded}>
              <Alert severity="error" sx={{ mt: 1 }}>
                <AlertTitle>Conversion Error</AlertTitle>
                <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                  {file.conversion_error}
                </Typography>
              </Alert>
            </Collapse>
          </Box>
        )}
      </CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        {/* <Button
          variant="outlined"
          color="error"
          size="small"
          startIcon={<Refresh />}
          onClick={() => processFile(file.id)}
          sx={{ width: "150px", ml: 2 }}
          fullWidth
        >
          Reprocess
        </Button> */}
      </Box>
    </Card>
  );
};

export default FileCard;
