import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  apiClient,
  baseURL,
  clearCookie,
  getCookie,
} from "../../api/apiClient";

export const Logout: React.FC = () => {
  SendLogoutApiRequest();
  return <Navigate to="/login" />;
};

const clearUserCredentials = (): boolean => {
  localStorage.removeItem("last_viewed_engagement");
  clearCookie("session_expiry");
  // clearCookie("access_token");
  clearCookie("csrf_token");
  clearCookie("sessionid");
  clearCookie("user_data");

  return true;
};

export const SendLogoutApiRequest = async () => {
  try {
    clearUserCredentials();
    const response = await apiClient.get(`${baseURL}/auth/cognito-logout/`, {});

    if (response && response.data.logout_url) {
      window.location.href = response.data.logout_url;
      return true;
    }
  } catch (error) {
    return false;
  }
};
